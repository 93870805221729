import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DayEndClosingPharmacy, DayEndViewModel } from '../models/dayend.closing.pharmacies.model';

@Injectable({
  providedIn: 'root'
})
export class DayEndClosingServiceService {

  private apiurl=environment.apiBaseUrl;
  constructor(private httpClient: HttpClient) { 
  }

  getDayEndClosingSubmissionDate():Observable<Date>
  {
    return this.httpClient.get<Date>(`${this.apiurl}/CtrlDayEnd/GetCurrentSubmissionDate`)
  }
  addDayEndClosing(DayEndClosingPharmacies : DayEndClosingPharmacy[]):Observable<DayEndViewModel>
  {    
    return this.httpClient.post<DayEndViewModel>(`${this.apiurl}/CtrlDayEnd/AddDayEndClosing`,DayEndClosingPharmacies)
  }
  getDayEndClosingPharmacies(status:number =0):Observable<DayEndClosingPharmacy[]>
  {    
    return this.httpClient.get<DayEndClosingPharmacy[]>(`${this.apiurl}/CtrlDayEnd/GetDayEndClosingPharmacies?submisstionStatus=`+status);
  }
}
