import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { DayEndClosingServiceService } from './services/day-end-closing-service.service';
import { StorageServiceService } from './services/storage-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'routing-demo';
  SubmissionDate: string | undefined = "";
  constructor(public authService: AuthService, private dayEndClosingService: DayEndClosingServiceService, private localstorageService: StorageServiceService) {

  }
  ngOnInit(): void {
    this.dayEndClosingService.getDayEndClosingSubmissionDate().subscribe(s => {
      if (s) {
        this.localstorageService.AddSubmissionDate(s);
      }
    });
    this.SubmissionDate = this.localstorageService.GetSubmissionDate()?.toDateString();
  }
}
