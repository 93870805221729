<nav class="navbar navbar-expand-sm navbar-dark bg-primary" style="height: 42px;">
  <div class="container-fluid">
    <!-- Navbar toggler button for small screens -->
    <span class="navbar-toggler-icon"></span>

    <!-- Navbar brand/logo -->
    <a class="navbar-brand" href="#">PrimeRx Enterprise Submission Portal</a>

    <!-- Navbar links -->
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav mx-auto">
        <li class="nav-item">
          <span class="text-white header-text" >Micro Merchant Systems</span>
        </li>
      </ul>
    </div>

    <!-- User information and icon on the right -->
    <div *ngIf="authService.isLoggedIn()" class="d-flex flex-column bd-highlight text-light me-2" style="text-align: right;">
      <small>{{(authService.user$| async)?.email}}</small>
      <small>Micro Merchant System</small>
    </div>

    <div class="d-flex"  *ngIf="authService.isLoggedIn()">
      <div class="text-light">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">        
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="true">
            <i class="bi bi-person-circle user-icon"></i>
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown" style="margin-top: -10px;margin-left: -90px;">
            <li><a (click)="logout()" role="button"  class="dropdown-item">Sign Out</a></li>
          </ul>
        </li>
      </ul>     
    </div>
  </div>
  </div>
</nav>