<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<app-navbar></app-navbar>
<main class="d-flex flex-nowrap">
  
<app-sidebar *ngIf="authService.isLoggedIn()"></app-sidebar>
<div class="main-container container-fluid">
  <router-outlet></router-outlet>
</div>
</main>

